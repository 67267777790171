<template>
	<div>
		<div class="read_box">
			<div class="read_box1">
				<div class="read_mainbox">
					<div class="pdfread_top">
						<div class="read_exitbtn" style="width: auto;">
							<a href="javascript:void(0)" class="fl_l" @click="exitReading">退出阅读</a>
						</div>
						<div class="epubread_readbox_top fl_r m_t0">
							<a class="readtype_selected" href="javascript:void(0)" style="cursor: default;">XML阅读</a>
						</div>
					</div>
					<div class="pdfread_content">
						<div class="xml_content" v-html="readCon">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="login_footer" style="float: left;">
			<p style="margin-bottom: 8px;"><span>© 版权所有 甘肃教育出版社有限责任公司 保留所有权利</span></p>
				<p><img style="width: 14px;margin-top: -4px;" src="@/assets/sign.png" alt=""> <a href="https://beian.mps.gov.cn/#/query/webSearch?code=62010202004573" target="_blank">甘公网安备62010202004573</a>   ICP备案信息 <a href="https://beian.miit.gov.cn/" target="_blank">陇ICP备2024012976号-2</a></p>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default{
		name:'XMLReading',
		data(){
			return{
				readCon:''
			}
		},
		methods:{
			readXML() { //阅读内容
				let url = this.$route.query.filePath;
				console.log(url)
				axios.get(url).then(res => {
					this.readCon = res.data
					this.readCon =  this.readCon.replace(/<title/g, "<paratitle").replace(/<\/title>/g, "</paratitle>");
				});
			},
			exitReading() { //退出阅读
				window.close()
			},
		},
		mounted(){
			this.readXML()
		}
	}
</script>

<style>
</style>
